import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1000.000000 1000.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 4965 l0 -4905 4900 0 c2695 0 4900 3 4900 8 0 9 -146 161 -357
374 l-156 156 23 32 c51 72 44 177 -16 230 -29 25 -43 30 -86 30 -46 0 -54 -4
-91 -41 l-41 -40 -533 533 -533 533 65 3 c49 2 65 7 65 17 0 12 -16 15 -76 15
-54 0 -84 5 -105 17 -38 23 -37 33 6 33 19 0 35 1 36 3 11 51 75 453 73 456
-2 2 -47 -12 -101 -30 -94 -33 -98 -35 -104 -69 -4 -19 -6 -36 -5 -37 2 -2 25
4 51 12 27 8 51 13 52 11 6 -5 -51 -326 -57 -326 -4 0 -41 36 -84 80 -43 44
-82 80 -87 80 -17 0 -4 127 26 258 5 20 2 22 -44 22 -47 0 -50 -1 -90 -55 -23
-30 -45 -55 -49 -55 -4 0 -118 111 -255 248 -232 232 -247 249 -247 283 0 44
-16 59 -69 66 -48 7 -95 48 -71 63 20 13 54 12 67 -1 6 -6 19 -9 29 -7 17 3
16 5 -6 26 -28 26 -48 27 -94 8 l-33 -14 -104 104 c-57 57 -101 105 -98 107 2
2 49 30 104 62 55 31 145 89 200 128 644 459 1035 1162 1074 1927 25 486 -56
889 -260 1300 -167 334 -425 648 -722 879 -283 219 -625 386 -973 474 -268 69
-374 81 -694 81 -246 0 -304 -3 -427 -22 -341 -54 -674 -170 -954 -332 -67
-39 -235 -151 -277 -185 -10 -7 -46 -37 -82 -66 -93 -75 -304 -290 -381 -389
-37 -47 -95 -129 -131 -182 l-64 -97 -665 665 -664 664 3608 0 c2403 0 3606 3
3602 10 -4 7 -1221 10 -3618 10 l-3613 0 -48 49 c-31 31 -47 55 -43 65 4 10 0
14 -12 11 -12 -2 -47 26 -105 85 -72 73 -86 93 -87 121 -1 33 -2 33 -8 6 -6
-25 -45 12 -709 675 -387 387 -714 711 -728 721 l-24 18 0 -4906z m5665 1810
c174 -31 359 -105 509 -202 65 -43 193 -146 208 -167 4 -5 -19 -27 -50 -47
-31 -21 -85 -60 -119 -88 l-62 -50 -33 27 c-44 36 -91 78 -147 130 -96 91
-138 99 -424 83 -122 -7 -311 -16 -421 -21 -222 -10 -228 -12 -246 -82 -12
-44 -16 -43 -96 19 l-49 38 -133 -3 c-153 -4 -154 -4 -62 70 148 118 225 160
440 244 176 68 464 89 685 49z m-100 -2311 c28 -2 113 -11 190 -19 77 -8 205
-20 284 -25 80 -6 147 -13 150 -16 15 -15 -158 -115 -278 -160 -37 -14 -97
-34 -136 -45 l-70 -19 -123 122 c-68 68 -132 134 -142 148 l-18 26 46 -4 c26
-2 70 -5 97 -8z m1475 -1599 c20 -24 6 -28 -15 -5 -10 11 -13 20 -8 20 6 0 16
-7 23 -15z m613 -606 c-4 -13 -9 -17 -15 -11 -11 11 -3 32 12 32 4 0 6 -9 3
-21z m1604 -1417 c15 -10 43 -75 43 -101 0 -31 -31 -101 -44 -101 -22 1 -126
111 -126 134 0 41 37 76 78 76 20 0 42 -4 49 -8z"/>
<path d="M3038 8493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3583 8493 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4383 8493 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4668 8493 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M4948 8493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5778 8493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6083 8493 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6298 8493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6598 8493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6792 8488 c-7 -7 -12 -19 -11 -27 0 -11 3 -10 8 4 6 16 20 21 72 26
l64 6 -60 1 c-36 1 -66 -3 -73 -10z"/>
<path d="M7338 8493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7408 8493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7688 8493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7832 8487 c-6 -6 -13 -26 -16 -42 -6 -28 -5 -28 7 4 7 19 22 38 33
42 15 6 15 8 3 8 -9 1 -21 -5 -27 -12z"/>
<path d="M7917 8485 c25 -10 33 -21 36 -47 l4 -33 1 36 c2 29 -3 38 -23 47
-14 7 -31 12 -38 12 -7 -1 2 -7 20 -15z"/>
<path d="M8268 8493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8545 8489 c22 -5 48 -19 58 -30 17 -21 24 -15 9 8 -10 15 -57 33
-85 32 -12 -1 -4 -5 18 -10z"/>
<path d="M4450 8486 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5527 8470 c3 -11 7 -20 9 -20 2 0 4 9 4 20 0 11 -4 20 -9 20 -5 0
-7 -9 -4 -20z"/>
<path d="M6130 8486 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M7010 8480 c0 -5 4 -10 8 -10 5 0 21 -27 37 -60 16 -33 30 -58 32
-56 6 6 -59 136 -68 136 -5 0 -9 -4 -9 -10z"/>
<path d="M7211 8474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8310 8486 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M8760 8481 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M8969 8473 c-8 -9 -9 -14 -2 -10 7 4 13 0 16 -10 3 -11 5 -8 6 10 1
32 -2 33 -20 10z"/>
<path d="M5851 8475 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M7581 8444 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2072 8425 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6996 8424 c-4 -14 -5 -28 -3 -31 3 -2 8 8 11 23 4 14 5 28 3 31 -3
2 -8 -8 -11 -23z"/>
<path d="M8782 8430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3352 8405 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M8622 8410 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1621 8413 c0 -4 4 -17 9 -28 8 -19 9 -19 9 2 1 12 -3 25 -9 28 -5 3
-10 3 -9 -2z"/>
<path d="M2602 8400 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5541 8404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5711 8404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5846 8403 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M7391 8404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7571 8404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8135 8409 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M6459 8383 c-5 -14 -8 -30 -6 -36 1 -5 8 7 14 27 13 42 7 49 -8 9z"/>
<path d="M6256 8372 c-4 -18 -5 -36 -2 -39 3 -4 6 1 6 10 0 14 11 17 53 19
l52 2 -47 3 c-34 3 -48 9 -51 21 -2 10 -6 4 -11 -16z"/>
<path d="M8792 8380 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8972 8380 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4171 8353 l-1 -33 -72 -2 -73 -2 80 -5 c44 -3 81 -6 81 -6 1 0 -2
18 -6 40 -7 39 -8 39 -9 8z"/>
<path d="M6760 8368 c0 -5 26 -7 58 -7 61 1 55 6 -15 11 -24 2 -43 0 -43 -4z"/>
<path d="M7381 8364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7560 8365 c0 -6 24 -8 62 -6 67 5 63 8 -14 13 -28 2 -48 -1 -48 -7z"/>
<path d="M8716 8365 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2975 8365 c24 -8 116 -12 103 -4 -7 5 -35 8 -63 8 -27 0 -45 -2 -40
-4z"/>
<path d="M3810 8366 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M4638 8363 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5287 8363 c-4 -3 -7 -18 -6 -32 1 -26 1 -25 10 3 9 31 7 41 -4 29z"/>
<path d="M7791 8344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1642 8320 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1921 8334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3360 8334 c0 -13 -11 -15 -70 -12 -39 2 -70 0 -70 -4 0 -3 34 -8 75
-10 72 -3 75 -2 75 20 0 12 -2 22 -5 22 -3 0 -5 -7 -5 -16z"/>
<path d="M4791 8334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8373 8310 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M8802 8330 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8962 8320 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M2610 8329 c0 -7 -24 -9 -70 -7 -39 2 -70 0 -70 -4 0 -5 34 -8 75 -8
60 0 75 3 75 15 0 8 -2 15 -5 15 -3 0 -5 -5 -5 -11z"/>
<path d="M2961 8324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3102 8315 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4390 8335 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M5550 8330 c0 -5 -33 -11 -72 -13 l-73 -3 78 -2 c63 -2 77 1 77 13 0
8 -2 15 -5 15 -3 0 -5 -4 -5 -10z"/>
<path d="M5920 8306 c0 -19 3 -37 8 -40 4 -2 6 13 4 35 -5 48 -12 51 -12 5z"/>
<path d="M7090 8336 c0 -17 55 -114 62 -110 4 3 8 10 8 15 0 6 -4 8 -9 5 -5
-4 -17 14 -27 39 -15 39 -34 68 -34 51z"/>
<path d="M7171 8324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7860 8336 c0 -11 50 -106 55 -106 3 0 -7 24 -21 53 -28 55 -34 65
-34 53z"/>
<path d="M8110 8306 c0 -19 3 -37 8 -40 4 -2 6 13 4 35 -5 48 -12 51 -12 5z"/>
<path d="M5776 8307 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M6966 8305 c-3 -9 -6 -24 -5 -33 0 -9 5 -4 10 12 9 32 6 48 -5 21z"/>
<path d="M7776 8294 c-4 -14 -5 -28 -3 -31 3 -2 8 8 11 23 4 14 5 28 3 31 -3
2 -8 -8 -11 -23z"/>
<path d="M3869 8282 c11 -16 28 -35 38 -43 9 -8 2 5 -17 29 -38 48 -52 57 -21
14z"/>
<path d="M4791 8284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5681 8284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7361 8284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7541 8284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1930 8266 c0 -14 4 -27 8 -30 5 -3 6 8 4 25 -5 35 -12 38 -12 5z"/>
<path d="M6450 8272 c0 -11 27 -42 37 -42 3 0 -2 10 -13 23 -10 12 -20 24 -21
26 -2 2 -3 -1 -3 -7z"/>
<path d="M8810 8270 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5562 8250 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7351 8244 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7528 8243 c-6 -22 -4 -23 65 -21 l72 2 -62 3 c-48 3 -62 8 -65 21
-3 13 -5 12 -10 -5z"/>
<path d="M8380 8256 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M8951 8244 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5947 8239 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M8144 8236 c12 -10 37 -15 60 -15 l41 2 -45 6 c-25 4 -52 10 -60 15
-8 4 -6 0 4 -8z"/>
<path d="M2747 8223 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M2958 8223 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4587 8223 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M4853 8223 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5998 8223 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M6253 8223 c20 -2 54 -2 75 0 20 2 3 4 -38 4 -41 0 -58 -2 -37 -4z"/>
<path d="M6523 8223 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6753 8223 c20 -2 54 -2 75 0 20 2 3 4 -38 4 -41 0 -58 -2 -37 -4z"/>
<path d="M8438 8223 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M9157 4280 c-13 -11 -27 -31 -31 -44 -4 -15 -13 -23 -23 -22 -10 0
-18 -4 -18 -11 0 -17 146 -164 156 -157 17 10 9 25 -46 82 -30 31 -55 64 -55
73 0 19 37 69 52 69 5 0 41 -30 79 -67 54 -52 72 -64 81 -55 10 10 -3 28 -57
82 -75 75 -96 83 -138 50z"/>
<path d="M8965 4098 c-83 -45 -64 -177 30 -208 45 -15 77 -7 110 26 78 78 -43
236 -140 182z m74 -32 c15 -8 35 -30 45 -49 52 -95 -60 -148 -121 -57 -30 43
-30 84 -1 104 28 20 41 20 77 2z"/>
<path d="M8740 4010 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M8794 3956 c-5 -13 15 -41 131 -184 1 -1 7 4 14 11 9 11 -3 32 -60
101 -39 47 -72 86 -75 86 -3 0 -7 -6 -10 -14z"/>
<path d="M8652 3921 c-12 -7 -12 -12 -1 -30 12 -17 12 -25 1 -42 -13 -20 -12
-21 11 -15 22 5 32 -3 81 -64 58 -71 77 -81 111 -57 24 18 11 41 -15 27 -16
-9 -26 -1 -69 52 -49 61 -62 88 -41 88 6 0 10 7 10 15 0 18 -12 19 -29 3 -10
-11 -15 -9 -29 9 -11 15 -22 20 -30 14z"/>
<path d="M8529 3759 c-16 -17 -29 -42 -29 -55 0 -63 72 -144 127 -144 12 0 38
14 58 31 27 24 35 39 35 65 0 19 -5 34 -10 34 -11 0 -15 -7 -24 -51 -7 -34
-55 -56 -84 -40 -51 27 -81 104 -55 140 14 20 22 23 58 18 23 -4 45 -3 49 0
13 14 -19 33 -56 33 -32 0 -46 -6 -69 -31z"/>
<path d="M8335 3638 c-30 -16 -55 -50 -55 -75 0 -34 16 -28 37 15 15 30 23 37
47 37 16 0 33 -7 40 -17 9 -14 3 -24 -32 -53 -34 -30 -42 -43 -42 -71 0 -68
87 -84 132 -24 11 16 26 26 33 24 27 -11 26 19 -1 59 -80 120 -104 135 -159
105z m114 -102 c17 -32 11 -55 -20 -78 -22 -16 -30 -18 -48 -9 -32 17 -27 55
12 85 39 32 41 32 56 2z"/>
<path d="M8121 3603 c-21 -26 -21 -53 2 -81 7 -9 7 -16 -1 -24 -18 -18 -14
-30 6 -24 13 4 28 -13 67 -79 27 -47 53 -85 57 -85 22 0 15 30 -26 100 -51 87
-53 95 -31 104 27 10 16 26 -15 20 -25 -6 -30 -3 -36 20 -4 18 -1 33 10 45 20
22 20 31 2 31 -8 0 -24 -12 -35 -27z"/>
<path d="M7990 3422 c-19 -9 -41 -30 -48 -45 -12 -24 -11 -32 3 -53 16 -25 19
-26 85 -19 61 6 69 5 79 -13 20 -38 -64 -83 -96 -51 -16 16 -28 -8 -13 -26 22
-27 75 -18 114 19 40 37 45 65 17 90 -16 15 -30 16 -90 10 l-71 -7 0 26 c0 35
40 63 69 48 27 -15 41 -14 41 4 0 9 -12 20 -26 25 -31 12 -23 13 -64 -8z"/>
<path d="M7770 3395 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M7815 3331 c-8 -14 86 -201 102 -201 21 0 16 16 -33 116 -44 90 -56
105 -69 85z"/>
<path d="M7667 3295 c4 -23 2 -35 -6 -35 -6 0 -11 -5 -11 -11 0 -7 9 -9 21 -6
18 5 26 -5 57 -74 36 -80 54 -96 92 -84 28 9 26 35 -2 32 -19 -2 -29 9 -55 63
-37 72 -40 88 -18 96 25 10 17 27 -10 20 -18 -5 -27 -1 -35 14 -18 35 -40 25
-33 -15z"/>
<path d="M7505 3200 c-25 -10 -40 -28 -49 -57 -11 -34 15 -29 35 6 20 34 51
44 78 24 29 -20 26 -39 -6 -52 -58 -22 -88 -66 -72 -109 16 -43 90 -50 125
-11 9 10 28 19 41 19 12 0 23 1 23 3 0 11 -68 157 -79 171 -14 17 -62 20 -96
6z m111 -115 c8 -33 1 -50 -30 -70 -19 -13 -29 -14 -45 -5 -33 17 -27 57 12
80 42 26 55 25 63 -5z"/>
<path d="M7256 3099 c-19 -15 -26 -30 -26 -54 0 -42 24 -58 83 -54 63 3 74 0
81 -17 14 -37 -52 -74 -89 -50 -31 20 -58 3 -30 -19 46 -36 155 6 155 60 0 38
-30 55 -99 55 -48 0 -63 4 -68 16 -15 38 49 71 83 43 22 -18 42 4 24 26 -18
22 -82 19 -114 -6z"/>
<path d="M9420 740 c0 -141 1 -150 19 -150 18 0 20 10 23 116 3 131 11 144 80
144 51 0 58 -17 58 -149 l0 -111 25 0 25 0 0 108 c0 129 10 152 68 152 64 0
67 -6 70 -140 l4 -120 25 0 25 0 -4 126 c-4 125 -4 127 -33 151 -24 21 -36 24
-77 21 -27 -3 -57 -13 -68 -23 -20 -18 -21 -18 -41 3 -25 27 -93 30 -128 6
-22 -16 -24 -16 -33 0 -5 9 -16 16 -23 16 -13 0 -15 -26 -15 -150z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
